import React, { useState } from 'react';
import axios from 'axios';

import Layout from '../../components/Layout';
import Personal from '../../components/Personal';

const API_URL = process.env.GATSBY_API_URL || 'http://localhost:9000';

const Umbrella = () => {
  const [personalInfo, setPersonalInfo] = useState({});
  const [submitted, setSubmitted] = useState(false);

  const onSubmit = async () => {
    try {
      await axios.post(`${API_URL}/quote`, {
        personalInfo,
      });
      setSubmitted(true);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Layout fullMenu>
      <article id="main">
        <header style={{ height: 300 }}>
          <h2>Umbrella Insurance</h2>
        </header>
        {submitted ? (
          <section className="wrapper style5">
            <h3 style={{ textAlign: 'center' }}>
              Thanks for submitting your info - Agency Insurance will be in
              contact with you shortly!
            </h3>
          </section>
        ) : (
          <section className="wrapper style5">
            <div
              className="inner"
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'row',
                justifyContent: 'space-around',
                flexWrap: 'wrap',
              }}
            >
              <h3 style={{ width: '100%', marginBottom: 0 }}>Personal Info</h3>
              <Personal
                onChange={setPersonalInfo}
                showAddress={false}
                showOccupation={false}
                showComments={true}
              />
            </div>
            <div className="inner">
              <button onClick={onSubmit}>Submit</button>
            </div>
          </section>
        )}
      </article>
    </Layout>
  );
};

export default Umbrella;
